import moment from 'moment'

export default {
  footerPropsDefault: { 'items-per-page-options': [10], showFirstLastPage: true },
  footerPropsErsTable: { 'items-per-page-options': [10, 20, 50], showFirstLastPage: true },
  footerPropsItem5: { 'items-per-page-options': [5], showFirstLastPage: true },
  custCodeToString: {
    LTRT01: '롯데렌트카',
    LTRT02: '롯데렌탈(수기)',
    LTAC01: '롯데오토케어',
    CPNG01: '쿠팡',
    AJCR01: 'AJ카리안 서비스',
    KRLY01: '마켓컬리',
    FRSL01: '컬리넥스트마일',
    OPML01: '오픈마일',
    CPLS01: '쿠팡로지스틱스',
    GSGL01: 'GS글로벌',
    SCAR01: '쏘카'

    
  },
  renderCustCode(custCode) {
    return this.custCodeToString[custCode] || custCode
  },
  renderErsIdToString(ersId) {
    if (!ersId) return ''
    const code = ersId.split('-')
    if (code.length === 3) {
      return this.renderCustCode(code[0])
    } else {
      return ersId
    }
  },
  requestCode: [
    { code: '09', text: '혼유견인' },
    { code: '10', text: '배터리' },
    { code: '11', text: '비상급유' },
    { code: '12', text: '타이어교체' },
    { code: '13', text: '잠금장치 해제' },
    { code: '14', text: '견인' },
    { code: '15', text: '구난' },
    { code: '17', text: '기타' },
    { code: '19', text: '타이어펑크' },
    { code: '20', text: '단순고장' },
    { code: '21', text: '전기차충전연결' },
    { code: '22', text: '사전(후)작업' },
    { code: '23', text: '타이어공기압충전' },
    { code: '24', text: '타이어펑크수리' },
    { code: '25', text: '요소수보충' },
  ],
  renderRequestCode(code) {
    return this.requestCode.find(e => e.code === code).text || code
  },
  ersQueryHourOptions: [
    { text: '전체', value: null },
    { text: '2일', value: 24 * 2 },
    { text: '3일', value: 24 * 3 },
    { text: '7일', value: 24 * 7 },
    { text: '30일', value: 24 * 30 },
  ],
  renderOpName(opName) {
    let str = ''
    if (opName.agency) { str += opName.agency }
    if (opName.agent) { str += (' | ' + opName.agent) }
    return str
  },
  mapLevelToDistance(level) {
    if (level < 2) return 20 * 3
    switch (level) {
      case 2 : return 30 * 3
      case 3 : return 50 * 3
      case 4 : return 100 * 3
      case 5 : return 250 * 3
      case 6 : return 500 * 3
      case 7 : return 1000 * 3
      case 8 : return 2000 * 3
      case 9 : return 4000 * 3
      case 10 : return 8000 * 3
      case 11 : return 16000 * 3
      case 12 : return 32000 * 3
    }
    return 64000
  },
  taskStatusCode: {
    NA: '접수',
    AA: '할당',
    AN: '배정',
    DP: '출동',
    AR: '도착',
    TS: '조치',
    TE: '완료',
    CB: '출동전취소',
    CA: '출동후취소',
    OF: '휴무',
    SB: '대기',
    FN: '승인'
  },
  renderVehicleCls(code = '10') {
    switch (code) {
      case '10': return '소형(승용차 등_2.5톤 미만)'
      case '11': return '중형(탑차 등_2.5톤 미만)'
      case '20': return '대형(2.5~4톤)'
      case '30': return '대형(4톤~8톤)'
      case '40': return '대형(8톤 초과)'
      default: return '소형(승용차 등_2.5톤 미만)'
    }
  },
  renderAway(awayLevel) {
    switch (awayLevel) {
      case '20' :
        return '준격오'
      case '30' :
        return '격오'
      default :
        return '일반'
    }
  },
  renderTaskStatus(code) {
    return this.taskStatusCode[code] || code
  },
  renderNight(isNight) {
    return isNight ? '<span style="color:white; background-color:black;">야</span>' : '<sapn>야</sapn>'
  },
  renderDispatchKm(dispatchKm) {
    return dispatchKm > 10 ? '<span style="color:white; background-color:red;">원</span>' : '<sapn>원</sapn>'
  },
  renderAwayLevel(awayLevel) {
    const str = this.renderAway(awayLevel)
    return '<span>' + str + '</span>'
  },
  renderProofType(proofType) {
    switch (proofType) {
      case 'DP' : return '출동'
      case 'TK' : return '조치'
      case 'EX' : return '할증'
      case 'TW' : return '견인'
      case 'ET' : return '기타'
      default : return proofType
    }
  },
  renderIsAfter(isAfter) {
    return isAfter ? '후' : '전'
  },
  renderViewpoint(viewpoint) {
    switch (viewpoint) {
      case 'front' : return '전면'
      case 'rear' : return '후면'
      case 'left' : return '좌측'
      case 'right' : return '우측'
      case 'none' : return ''
      default : return ''
    }
  },
  payCodeOptions: [
    { text: '전액유상', value: 'A' },
    { text: '부분유상', value: 'C' },
    { text: '무상', value: 'N' },
  ],
  renderPayCode(code) {
    return this.payCodeOptions.find(e => e.value === code).text || code
  },
  renderRole(role) {
    switch (role) {
      case 'CD' : return '팀장'
      case 'CT' : return '상담사'
      default : return role
    }
  },
  roleOptions: [
    { text: '팀장', value: 'CD' },
    { text: '상담사', value: 'CT' },
  ],
  renderCounselorStatus(status) {
    switch (status) {
      case '00' : return '정상'
      default: return status
    }
  },
  renderCounselorRole(role) {
    return role === 'T' ? '상담사' : '팀장'
  },

  // for masking.
  checkNull(str) {
    return typeof str === 'undefined' || str === null || str === ''
  },
  /*
  ※ 이메일 마스킹
  ex1) 원본 데이터 : abcdefg12345@naver.com
       변경 데이터 : ab**********@naver.com
  ex2) 원본 데이터 : abcdefg12345@naver.com
       변경 데이터 : ab**********@nav******
  */
  maskEmail(str) {
    if (!str) return str
    const originStr = str
    const emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
    let strLength

    if (this.checkNull(emailStr) === true) {
      return str
    } else {
      strLength = emailStr.toString().split('@')[0].length - 3

      // ex1) abcdefg12345@naver.com => ab**********@naver.com
      // return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*')

      // ex2) abcdefg12345@naver.com => ab**********@nav******
      return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*').replace(/.{6}$/, '******')
    }
  },
  /*
  ※ 휴대폰 번호 마스킹
  ex1) 원본 데이터 : 01012345678, 변경 데이터 : 010****5678
  ex2) 원본 데이터 : 010-1234-5678, 변경 데이터 : 010-****-5678
  ex3) 원본 데이터 : 0111234567, 변경 데이터 : 011***4567
  ex4) 원본 데이터 : 011-123-4567, 변경 데이터 : 011-***-4567
  */
  maskPhone(str) {
    const originStr = str
    let phoneStr
    let maskingStr

    if(this.checkNull(originStr) === true){
      return originStr
    }

    if (originStr.toString().split('-').length !== 3) { // 1) -가 없는 경우
      phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi)
      if (this.checkNull(phoneStr) === true){
        return originStr
      }

      if (originStr.length < 11) { // 1.1) 0110000000
        maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'))
      } else { // 1.2) 01000000000
        maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'))
      }
    } else { // 2) -가 있는 경우
      phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi)
      if (this.checkNull(phoneStr) === true) {
        return originStr
      }

      if (/-[0-9]{3}-/.test(phoneStr)) { // 2.1) 00-000-0000
        maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, '-***-'))
      } else if (/-[0-9]{4}-/.test(phoneStr)) { // 2.2) 00-0000-0000
        maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, '-****-'))
      }
    }

    return maskingStr
  },
  /*
  ※ 주민등록 번호 마스킹 (Resident Registration Number, RRN Masking)
  ex1) 원본 데이터 : 990101-1234567, 변경 데이터 : 990101-1******
  ex2) 원본 데이터 : 9901011234567, 변경 데이터 : 9901011******
  */
  maskRrn (str) {
    const originStr = str
    let rrnStr
    let maskingStr
    // let strLength

    if(this.checkNull(originStr) === true){
      return originStr
    }

    rrnStr = originStr.match(/(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4]{1}[0-9]{6}\b/gi)
    if (this.checkNull(rrnStr) === false) {
      // strLength = rrnStr.toString().split('-').length
      maskingStr = originStr.toString().replace(rrnStr, rrnStr.toString().replace(/(-?)([1-4]{1})([0-9]{6})\b/gi,'$1$2******'))
    } else {
      rrnStr = originStr.match(/\d{13}/gi)
      if (this.checkNull(rrnStr) === false) {
        // strLength = rrnStr.toString().split('-').length
        maskingStr = originStr.toString().replace(rrnStr, rrnStr.toString().replace(/([0-9]{6})$/gi,'******'))
      } else {
        return originStr
      }
    }
    return maskingStr
  },
  /*
  ※ 이름 마스킹
  ex1) 원본 데이터 : 갓댐희, 변경 데이터 : 갓댐*
  ex2) 원본 데이터 : 하늘에수, 변경 데이터 : 하늘**
  ex3) 원본 데이터 : 갓댐, 변경 데이터 : 갓*
  */
  maskName(str) {
    const originStr = str
    let maskingStr

    if (this.checkNull(originStr) === true) {
      return originStr
    }

    const strLength = originStr.length

    if (strLength < 3) {
      maskingStr = originStr.replace(/(?<=.{1})./gi, '*')
    } else {
      maskingStr = originStr.replace(/(?<=.{2})./gi, '*')
    }

    return maskingStr
  },

// 차량번호
  maskPlate(strCar) {
    if (this.checkNull(strCar) === true) {
      return strCar
    }
    const pattern = /.{3}$/ // 정규식
    return strCar.replace(pattern, '***')
  },

  maskBizNo(num) {
    if (this.checkNull(num) === true) {
      return num
    }
    const str = num.replace(/-/g, '')
    return str.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****')
  },
  maskIdMask(id) {
    if (this.checkNull(id) === true) return id
    return id.slice(0, 3).padEnd(id.length, '*')
  },
  toDate(iso) {
    if (iso) return moment(iso).format('YYYY-MM-DD HH:mm')
  },
  toDateYmd(ymd) {
    if (ymd) return moment(ymd, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')
  },
  comma(num){
    let len, point, str;

    num = num + "";
    point = num.length % 3;
    len = num.length;

    str = num.substring(0, point);
    while (point < len) {
      if (str !== "") str += ",";
      str += num.substring(point, point + 3);
      point += 3;
    }
    return str;
  }
}
